import { ProgramType } from '@newfront-insurance/account-api';
import { NotFoundError } from '@newfront-insurance/data-layer-client';
import type { Route } from '@newfront-insurance/next-router-provider';
import { useRouter } from '@newfront-insurance/next-router-provider';
import { useProvider } from '@newfront-insurance/react-provision';
import type { PropsWithChildren } from 'react';
import React, { useEffect } from 'react';

import { AccountLayout } from '@/client/components/layouts/account-layout';
import { LoadingSpinner } from '@/client/components/query-loader/loading-spinner';
import { AccountDataProvider } from '@/client/providers/account-data';
import { pageRoutes } from '@/client/routes';

export default function LandingPage(): JSX.Element {
  return (
    <AccountLayout>
      <Redirect>
        <LoadingSpinner loadingText="Loading..." />
      </Redirect>
    </AccountLayout>
  );
}

function Redirect(props: PropsWithChildren<unknown>): JSX.Element {
  const { children } = props;
  const { uuid, programType } = useProvider(AccountDataProvider);
  const { pushRoute } = useRouter();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    pushRoute(getProgramTypeRoute(uuid, programType));
  }, [uuid, programType, pushRoute]);

  return <>{children}</>;
}

/**
 * Get the root route for a servicing type.
 */
function getProgramTypeRoute(accountUuid: string, programType: ProgramType): Route {
  if (programType === ProgramType.PROPERTY_AND_CASUALTY) {
    return pageRoutes.clientHome({ accountUuid });
  }
  if (programType === ProgramType.EMPLOYEE_BENEFITS) {
    return pageRoutes.viewPlans({ accountUuid });
  }
  throw new NotFoundError('Program type not found.');
}
